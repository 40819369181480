<template>
  <div class="vulnerabilityCenterAll">
    <div class="vulnerabilityCenterFirst">
      <div class="vulnerabilityCenterFirst1">
        <h1>御炼靶场</h1>
        <p>
          为用户提供便捷的Windows靶场环境搭建和启动解决方案。
        </p>
        <p>
           Windows网络环境的安全学习和实践场所。通过提供全面、灵活的实验环境，Windows靶场帮助用户深入了解Windows系统的安全特性，掌握渗透测试技能，提高对系统漏洞的分析和应对能力。
        </p>
      </div>
    </div>
    <div class="vulnerabilityCenterThird">
      <div class="vulnerabilityCenterThird1">
        <div class="vulnerabilityCenterThird1Left">
          <img src="../../../../assets/img/proctCenter/5.webp" alt="" />
        </div>
        <div class="vulnerabilityCenterThird1Right">
          <p>
           通过简单直观的部署流程和一键启动功能，用户能够轻松创建和运行各种丰富多样的靶场场景，进行全面的安全技能训练。系统的用户友好界面、实时性能监控以及灵活的配置选项，使其成为初学者和专业安全从业者都能够愉快使用的理想平台。通过模拟真实的攻击和防御情境，该系统助力用户提升在Windows环境下的安全技能，为网络安全培训提供了全方位的支持。
          </p>
          <p>
            Windows网络环境的安全学习和实践场所。通过提供全面、灵活的实验环境，Windows靶场帮助用户深入了解Windows系统的安全特性，掌握渗透测试技能，提高对系统漏洞的分析和应对能力。
          </p>
        </div>
      </div>
    </div>
    <div class="codeAuditThird">
      <h1>积蓄新力量，开启新跨越</h1>
      <div class="codeAuditThird1">
        <ul>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/11-1.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">真实模拟环境</p>
              <span class="codeAuditThird1-2-2">
                　 Windows靶场通过模拟真实的Windows网络环境，提供真实感的实验场景，用户可以在安全的环境中进行学习和实践，不会对实际系统造成影响。
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/22-2.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">多样化的实验场景</p>
              <span class="codeAuditThird1-2-2">
                平台设计了丰富的实验场景，涵盖了各种Windows系统版本和常见应用程序，帮助用户面对不同环境的渗透测试和漏洞分析挑战。
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/33-1.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">灵活的学习路径</p>
              <span class="codeAuditThird1-2-2">
                用户可以根据自己的需求和水平选择不同难度级别的实验，构建个性化的学习路径，逐步提升技能水平。
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/44.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">持续更新和支持</p>
              <span class="codeAuditThird1-2-2">
                平台会定期更新实验场景，以适应不断变化的安全威胁和技术发展，同时提供专业的技术支持，保障用户在学习过程中的顺利进行。
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.vulnerabilityCenterAll {
  width: 100%;
  .vulnerabilityCenterFirst {
    background-image: url(../../../../assets/img/proctCenter/5.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 200px 0;
    .vulnerabilityCenterFirst1 {
      width: 30%;
      margin: 0 auto;
      padding-bottom: 50px;
      h1 {
        font-size: 35px;
        color: #fff;
      }
      p {
        font-size: 18px;
        margin-top: 10px;
        color: #fff;
      }
    }
  }
  .vulnerabilityCenterSecond {
    margin-top: 40px;
    background: #f1f5ff;
    padding: 30px;
    display: flex;
    justify-content: center;
    img {
      width: 800px;
    }
  }
  .vulnerabilityCenterThird {
    margin-top: 40px;
    background: #f1f5ff;
    padding: 30px;
    .vulnerabilityCenterThird1 {
      display: flex;
      justify-content: center;
      align-items: center;
      .vulnerabilityCenterThird1Left {
        margin-right: 30px;
        img {
          width: 458px;
          height: 350px;
        }
      }
      .vulnerabilityCenterThird1Right {
        width: 30%;
        p {
          font-size: 18px;
          margin-top: 10px;
        }
      }
    }
  }
  .codeAuditThird {
    width: 100%;
    margin-top: 40px;
    background: #f1f5ff;
    padding: 50px 0 20px 0;
    h1 {
      text-align: center;
    }
    .codeAuditThird11{
        width: 220px;
        text-align: center;
        margin-top: 20px;
        font-size: 20px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 40px;
      li {
        width: 35.5%;
        display: flex;
        padding: 20px;
        background-image: linear-gradient(180deg, #f4f7ff, #fcfcfe);
        border-radius: 12px;
        margin-top: 20px;
        margin-left: 20px;
        .codeAuditThird1-1 {
          img {
            width: 48px;
            height: 48px;
            margin-right: 20px;
          }
        }
        .codeAuditThird1-2 {
          .codeAuditThird1-2-1 {
            font-size: 24px;
            color: #1f2329;
          }
          .codeAuditThird1-2-2 {
            font-size: 16px;
            color: #51565d;
            margin-top: 20px;
            line-height: 26px;
          }
        }
      }
    }
  }
}
</style>